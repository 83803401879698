import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, notification, Carousel, Radio } from 'antd';
import UseAPI from 'lib/api/UseAPI';
import api_config from 'lib/api/common';
import validator from 'validator';
import Logo from '../../assets/img/logo.png';
import LPPromoItem1 from './LPPromoItem1';
import { StoreContext } from 'store';
import { GoogleLogin } from '@react-oauth/google';

import './index.css';

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};
const Login = () => {
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, desc) => {
    api[type]({
      message: message,
      description: desc,
    });
  };
  const navigate = useNavigate();
  const location = useLocation();

  const { state, dispatch } = useContext(StoreContext);

  useEffect(() => {

    const params = new URLSearchParams(location.search);
    const nextUrl = params.get('next');

    // Store `next` parameter in session storage if it exists
    if (nextUrl) {
      sessionStorage.setItem('nextUrl', nextUrl);
    }

    dispatch({ type: 'UPDATE_STATE', payload: { show_chat_widget: false } });
    // if (window.Tawk_API) {
    //   if (location.pathname === '/login' || location.pathname === '/reset-password' || location.pathname === '/signup') {
    //     window.Tawk_API.hideWidget();
    //   } else {
    //     window.Tawk_API.showWidget();
    //   }
    // }
    return () => {};
  }, []);

  const [pwd, setPwd] = useState('');

  const [user, setUser] = useState('');
  const [isUserLoading, setUserloading] = useState(false);

  const [errMsg, setErrMsg] = useState('');

  const handleChange = (e) => {
    setUser(e.target.value);
  };

  const handleLogIn = async (event) => {
    event.preventDefault();

    setUserloading(true);
    let payload;
    if (validator.isEmail(user)) {
      payload = {
        username: user.trim(),
        password: pwd.trim(),
      };
    } else {
      setUserloading(false);
      openNotificationWithIcon('error', 'Failed to login', 'Please enter your valid email');
      return;
    }

    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/login/';
    api_config.ABORT_CONTROLLER = new AbortController();
    const signal = api_config.ABORT_CONTROLLER.signal;

    try {
      UseAPI(signal, url, 'POST', payload)
        .then(function (data) {
          // handle data here
          if (data.success) {
            const token = data.token;
            localStorage.clear();
            localStorage.setItem('loggedIn', true);
            localStorage.setItem('user-token', token);
            localStorage.setItem('k_', data.user_info["email"] === "codenginebdup@gmail.com");
            openNotificationWithIcon('success', 'Login Success', 'You are now logged in.');
            
            const nextUrl = sessionStorage.getItem('nextUrl');
            if(nextUrl) {
              sessionStorage.removeItem('nextUrl');
              navigate(nextUrl);
            }
            else {
              navigate('/');
            }
            
          } else {
            openNotificationWithIcon(
              'error',
              'Failed to login',
              'Your Username or Password is incorrect, please enter valid Username and Password.      ',
            );
          }
          setUserloading(false);
        })
        .catch(function (err) {
          openNotificationWithIcon(
            'error',
            'Failed to login',
            'Your Username or Password is incorrect, please enter valid Username and Password.      ',
          );

          setUserloading(false);
        });
    } catch (e) {
      setUserloading(false);
      openNotificationWithIcon(
        'error',
        'Failed to login',
        'Your Username or Password is incorrect, please enter valid Username and Password.      ',
      );
    }
  };

  return (
    <>
      <div className="flex-container">
        <Carousel className="auth-promo-slider">
          <LPPromoItem1 />
        </Carousel>
        <div className="login-slider">
          <div className="logo" style={{ textAlign: 'center', marginBottom: '80px' }}>
            <img style={{ width: '160px' }} src={Logo} alt="Unmixr" />
          </div>
          {/* <h1
            style={{
              textAlign: 'center',
              marginTop: '80px',
              fontSize: 22,
              color: 'rgb(34, 34, 34) !important',
              marginBottom: '30px'
            }}
          >
            Welcome back
          </h1> */}
          {contextHolder}
          
          <div>
          <GoogleLogin
            theme='outline' 
            size='large' 
            text='continue_with' 
            shape='pill' 
            logo_alignment='left' 
            useOneTap='true' 
            width='360px' 
            onSuccess={credentialResponse => {
              //console.log(credentialResponse);
              let url = api_config.BASE_BACKEND_ENDPOINT + 'api/social-auth/';
              let apiConfigController = new AbortController();
              const signal = apiConfigController.signal;
              let accessToken = credentialResponse.credential;
              let payload = {
                access_token: accessToken
              }
              UseAPI(signal, url, 'POST', payload)
              .then(function (data) {
                // handle data here
                if (data.success) {
                  const token = data.token;
                  localStorage.clear();
                  localStorage.setItem('loggedIn', true);
                  localStorage.setItem('user-token', token);
                  openNotificationWithIcon('success', 'Login Success', 'You are now logged in.');
                  const nextUrl = sessionStorage.getItem('nextUrl');
                  if(nextUrl) {
                    sessionStorage.removeItem('nextUrl');
                    navigate(nextUrl);
                  }
                  else {
                    navigate('/');
                  }
                } else {
                  openNotificationWithIcon(
                    'error',
                    'Failed to login',
                    'Could not login. Please retry again later.',
                  );
                }
                setUserloading(false);
              })
              .catch(function (err) {
                openNotificationWithIcon(
                  'error',
                  'Failed to login',
                  'Could not login. Please retry again later.',
                );

                setUserloading(false);
              });
            }}
            onError={() => {
              openNotificationWithIcon('error', 'Failed to login', 'Could not login. Please retry again later.');
            }}
          />
          </div>

          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', marginTop: '30px', marginBottom: '30px' }}>
            <span>OR</span>
          </div>

          <form className="auth-form" onSubmit={handleLogIn}>
            <input
              type="email"
              id="username"
              placeholder="Enter your email"
              autoComplete="off"
              onChange={handleChange}
              value={user}
              required
            />

            <input
              type="password"
              id="password"
              placeholder="Enter your password"
              autoComplete="off"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
            />
            <div style={{marginTop: '7px'}}>By signing in, I agree to the <a style={{textDecoration: 'underline'}} target='_blank' href="https://unmixr.com/privacy/">privacy policy</a> and <a style={{textDecoration: 'underline'}} target='_blank' href="https://unmixr.com/terms/">terms and conditions</a>.</div>
            {errMsg && <p className="error-message">{errMsg}</p>}
            <button className="login_btn">
              {isUserLoading && (
                <Spin
                  indicator={antIcon}
                  style={{
                    color: 'white',
                    marginRight: '10px',
                    marginTop: '3px',
                  }}
                />
              )}
              <span style={{ fontSize: '16px' }}> {isUserLoading ? 'Logging' : 'Login'} </span>{' '}
            </button>
          </form>
          <p style={{ textAlign: 'center', marginTop: 10, marginBottom: 0 }}>
            Forgot your password?
            <Link style={{ marginLeft: 5 }} to="/reset-password">
              Reset now
            </Link>
          </p>
          <p style={{ textAlign: 'center', marginTop: 0 }}>
            Don't have any account yet?
            <Link style={{ marginLeft: 5 }} to="/signup">
              Create new Account{' '}
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
