import { StoreContext } from 'store';
import Cookies from 'js-cookie';

const UseAPI = async (signal, url, method, payload, includeCredentials = true) => {
  // Retrieve the token from local storage
  const token = localStorage.getItem('user-token');

  const csrfToken = Cookies.get('csrftoken');

  try {
    let options = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
        'X-CSRFToken': csrfToken,
      },
      body: JSON.stringify(payload),
      signal,
      credentials: includeCredentials ? 'include' : 'same-origin',
    };

    if (method === 'GET') {
      options = {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
          'X-CSRFToken': csrfToken,
        },
        signal,
        credentials: includeCredentials ? 'include' : 'same-origin',
      };
    }

    const response = await fetch(url, options);

    if (!response.ok) {
      if (response.status === 401) {
        // setIsLoggedIn(false);
        // navigate('/login');
        localStorage.clear();
        const result = { httpStatusCode: 401, code: 401, message: 'Unauthorized' };
        return result;
      } else if (response.status === 500 || response.status === 502) {
        const result = {
          httpStatusCode: response.status,
          code: response.status,
          message: 'Server error',
        };
        return result;
      } else {
        const result = {
          httpStatusCode: response.status,
          code: response.status,
          message: response.message || 'Invalid request',
        };
        return result;
      }
    }

    if (response.status === 204) {
      const result = { httpStatusCode: 204, code: 200, message: 'Deleted' };
      return result;
    } else {
      const result = await response.json();
      return result;
    }
  } catch (error) {
    //window.location.reload();
    console.error('Error fetching data:', error);
    throw error;
  }
};

export default UseAPI;
