import React from 'react';

import PageLayout from '../../layout/PageLayout';
import './index.css';
import { Row, Col, Tabs } from 'antd';

const FAQComponent = () => {
  return (
    <PageLayout>
      <Row gutter={16} style={{ marginTop: 30, marginLeft: 30 }}>
        <Col span={24}>
          <div className="support-heading">
            <h1>Frequently Asked Questions</h1>
          </div>
        </Col>
        <Col span={24} style={{ marginBottom: 20 }}>
          <div className="fq-box">
            <h3>Help & Support</h3>
            <p>For any query, support or any suggestion, please contact us at <a mailto="support@unmixr.com" style={{borderBottom: '2px solid #1890ff'}}>support@unmixr.com</a></p>
          </div>
        </Col>
        <Col span={24} style={{ marginBottom: 20 }}>
          <div className="fq-box">
            <h3>Can I change my plan at any time?</h3>
            <p>Yes, you can upgrade or downgrade your plan at any time.</p>{' '}
          </div>
        </Col>
        <Col span={24} style={{ marginBottom: 20 }}>
          <div className="fq-box">
            <h3>
              If all of the words are not used during the month, do they roll over to the next
              month?
            </h3>
            <p>No, unused words do not roll over to the next month. Use them up.</p>{' '}
          </div>
        </Col>
        <Col span={24} style={{ marginBottom: 20 }}>
          <div className="fq-box">
            <h3>Are there any hidden fees?</h3>
            <p>No, there are no hidden fees. You just pay the monthly subscription fee.</p>{' '}
          </div>
        </Col>
        <Col span={24} style={{ marginBottom: 20 }}>
          <div className="fq-box">
            <h3>Can I change my plan at any time?</h3>
            <p>Yes, you can upgrade or downgrade your plan at any time.</p>{' '}
          </div>
        </Col>
        <Col span={24} style={{ marginBottom: 20 }}>
          <div className="fq-box">
            <h3>How do I cancel my subscription?</h3>
            <p>
              You can easily cancel your subscription at any time by going to your My Plan. Go to My Plan {'->'} Manage Your Billing {'->'} You will be redirected to our billing portal {'->'} Cancel Plan 
            </p>{' '}
          </div>
        </Col>
        <Col span={24} style={{ marginBottom: 20 }}>
          <div className="fq-box">
            <h3>Do you offer any kind of support?</h3>
            <p>Yes, we offer email support for all of our customers. For any query or support please reach out us at <a mailto="support@unmixr.com" style={{borderBottom: '2px solid #1890ff'}}>support@unmixr.com</a></p>{' '}
          </div>
        </Col>
        <Col span={24} style={{ marginBottom: 20 }}>
          <div className="fq-box">
            <h3>What payment methods are accepted?</h3>
            <p>
              We supports all major credit cards and debit cards. Your payment method is secured by 
              <a target='__blank' style={{borderBottom: '2px solid #1890ff'}} href="https://stripe.com/"> Stripe</a>. 
            </p>{' '}
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default FAQComponent;
