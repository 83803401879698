import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './index.css';
import ReactCodeInput from 'react-code-input';
import axios from 'axios';
import { Spin, Button, notification, Carousel } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import api_config from 'lib/api/common';
import Logo from '../../assets/img/logo.png';
import LPPromoItem1 from './LPPromoItem1';

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
const Reset = () => {
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, desc) => {
    api[type]({
      message: message,
      description: desc,
    });
  };
  const navigate = useNavigate();

  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [pwd, setPwd] = useState('');
  const [matchPwd, setMatchPwd] = useState('');
  const [isEnableSignUp, setEnableSignUp] = useState(false);
  const [isVerifyLoading, setVerifyLoading] = useState(false);
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [isUserLoading, setUserloading] = useState(false);

  const [user, setUser] = useState('');
  const [isDisableduser, setDisableUser] = useState(false);
  const [isVerifyStatus, setVerifyCodeStatus] = useState(false);

  const [code, setCode] = useState('');
  const [isEnablecode, setEnableCode] = useState(false);
  const [isEnableSignUpAPI, setEnableAPISignUp] = useState(false);

  const [errMsg, setErrMsg] = useState('');

  const handleChange = (e) => {
    setUser(e.target.value);
  };
  const handleCode = (value) => {
    setCode(value);
  };

  const handleAPISubmit = async () => {
    try {
      setUserloading(true);
      const response = await axios.post(
        api_config.BASE_BACKEND_ENDPOINT + 'api/send-verification-code/',
        {
          email: user,
          signup: false,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );
      //console.log(response);
      setUserloading(false);

      if (response.data.code === 200) {
        openNotificationWithIcon(
          'success',
          'Email Verification Code',
          'Successfully Email Verification code.',
        );

        setVerifyCodeStatus(true);
        // Email Disabled
        setDisableUser(true);
        // Enable Phone code
        setEnableCode(true);
        setSubmitLoading(false);
        // api/verify-code/ POST
        setVerifyLoading(true);
        setErrMsg('');

        // console.log(response.data.message);
      } else {
        if (response.data.code === 400) {
          openNotificationWithIcon('error', 'Email Required', 'User email required ');

          //setErrMsg(response.data.message);
        } else {
          openNotificationWithIcon(
            'error',
            'error',
            'The provided email does not exist in our system',
          );
        }
      }
    } catch (err) {
      setUserloading(false);

      setErrMsg(err.response.data.error);
    }
  };
  const handleVerifyCode = async () => {
    try {
      setUserloading(true);

      const response = await axios.post(
        api_config.BASE_BACKEND_ENDPOINT + 'api/verify-code/ ',
        {
          email: user,
          code: code,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );
      setUserloading(false);

      // console.log(response);
      if (response.data.code === 200) {
        // Email Disabled

        setVerifyLoading(false);
        setDisableUser(true);
        // Enable Phone code
        setEnableCode(false);
        setEnableSignUp(true);
        setSubmitLoading(true);
        setEnableAPISignUp(true);
        setErrMsg('');
        //openNotificationWithIcon('success', 'Verify Code', 'Successfully Verification your code ');
      } else {
        openNotificationWithIcon(
          'error',
          'Code verification failed',
          'The security code is invalid or expired. Please retry again',
        );

        setErrMsg(response.data.message);
      }
    } catch (err) {
      setUserloading(false);

      //setErrMsg(err.response.data.message);
    }
  };
  const handleresetPassword = async () => {
    try {
      setUserloading(true);

      const response = await axios.post(
        api_config.BASE_BACKEND_ENDPOINT + 'api/update-password/',
        {
          email: user,
          code: code,
          password1: pwd,
          password2: matchPwd,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );
      setUserloading(false);

      if (response.data.code === 200) {
        openNotificationWithIcon(
          'success',
          'Password reset successful',
          'You password has been reset. Please login to continue',
        );

        setTimeout(function () {
          navigate('/login');
        }, 1000);
      } else {
        openNotificationWithIcon(
          'error',
          'Failed to reset password',
          'Failed to reset password. Please retry later.',
        );
      }
    } catch (err) {
      setUserloading(false);

      if (err.response.status === 400) {
        openNotificationWithIcon(
          'error',
          'Failed to reset password',
          'Failed to reset password. Please retry later.',
        );

        //setErrMsg(err.response.data.error);
      }
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (isVerifyStatus) {
      if (isEnableSignUpAPI) {
        handleresetPassword();
      } else {
        handleVerifyCode();
      }
    } else {
      handleAPISubmit();
    }
  };

  return (
    <>
      <div className="flex-container">
        <Carousel>
          <LPPromoItem1 />
        </Carousel>
        <div className="login-slider">
          <div className="logo" style={{ textAlign: 'center' }}>
            <img style={{ width: '160px' }} src={Logo} alt="Unmixr" />
          </div>
          <h1
            style={{
              textAlign: 'center',
              marginTop: '80px',
              fontSize: 22,
              color: 'rgb(34, 34, 34) !important',
            }}
          >
            Reset password
          </h1>
          {contextHolder}
          <form className="auth-form" onSubmit={handleSubmit}>
            <input
              type="text"
              id="username"
              disabled={isDisableduser}
              placeholder="Enter your email"
              autoComplete="off"
              onChange={handleChange}
              value={user}
              required
            />
            {isEnableSignUp && (
              <div>
                <input
                  type="password"
                  id="password"
                  placeholder="Enter your password"
                  autoComplete="off"
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  required
                />
                <input
                  type="password"
                  id="confirm_pwd"
                  placeholder="Enter your confirm password"
                  autoComplete="off"
                  onChange={(e) => setMatchPwd(e.target.value)}
                  value={matchPwd}
                  required
                />
              </div>
            )}
            {isEnablecode && (
              <ReactCodeInput
                type="text"
                className="auth-code"
                inputStyle={{
                  marginRight: '9px',
                  height: '35px',
                  padding: ' 6px 10px',
                  outline: 'none',
                  fontSize: '24px',
                  border: '1px solid #ddd',
                }}
                onChange={handleCode}
                fields={6}
              />
            )}
            {errMsg && <p className="error-message">{errMsg}</p>}

            <button className="login_btn">
              {isVerifyLoading ? (
                <div>
                  {isUserLoading && (
                    <Spin
                      indicator={antIcon}
                      style={{
                        color: 'white',
                        marginRight: '10px',
                        marginTop: '3px',
                      }}
                    />
                  )}
                  <span>Verify</span>
                </div>
              ) : isSubmitLoading ? (
                <div>
                  {isUserLoading && (
                    <Spin
                      indicator={antIcon}
                      style={{
                        color: 'white',
                        marginRight: '10px',
                        marginTop: '3px',
                      }}
                    />
                  )}
                  <span>Reset password</span>
                </div>
              ) : (
                <div>
                  {isUserLoading && (
                    <Spin
                      indicator={antIcon}
                      style={{
                        color: 'white',
                        marginRight: '10px',
                        marginTop: '3px',
                      }}
                    />
                  )}
                  <span>Continue</span>
                </div>
              )}
            </button>
          </form>
          <p style={{ textAlign: 'center', marginTop: 10 }}>
            Already have an account with us?
            <Link style={{ marginLeft: 5 }} className="create" to="/login">
              Login
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Reset;
