import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Layout, PageHeader } from 'antd';
import { StoreContext } from 'store';

import './PageLayout.less';

const { Content } = Layout;

const PageLayout = ({ title, children }) => {
  const { state, dispatch } = useContext(StoreContext);

  return (
    <>
      {/* <PageHeader title={title} /> */}
      <Content className="page-content">{children}</Content>
      {/* {
          state.show_login_modal && 
          <div>Modal Modal</div>
      } */}
    </>
  );
};

PageLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default PageLayout;
