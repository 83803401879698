import React, { Suspense, lazy } from 'react';
import ReactDOM, { createRoot } from 'react-dom';
import ReactGA from 'react-ga4';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'react-h5-audio-player/lib/styles.css';


import App from './App';

ReactGA.initialize('G-1QPRQTZTER');
ReactDOM.render(
  <GoogleOAuthProvider clientId="416618889523-9su5qrncuj3k2qrajqgnpdncfp0jue1b.apps.googleusercontent.com">
    <App />
  </GoogleOAuthProvider>,
  document.querySelector('#root'),
);
