import React, { useEffect, useState } from 'react';
import { Route, useNavigate } from 'react-router-dom';
import UseAPI from 'lib/api/UseAPI';
import api_config from 'lib/api/common';


const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const performLogOut = () => {
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/logout/';
    let abortController = new AbortController();
    const signal = abortController.signal;
    let payload = {}
  
    UseAPI(signal, url, "GET", payload)
    .then(function(data) {
        // handle data here
        if(data.code === 200) {
          
        }
        else {
            //message.error("Error connecting to server");
        }
        localStorage.clear();
        setIsLoggedIn(false);
        return navigate('/login');
    })
    .catch(function(err) {
        if (err.name === 'AbortError') {
            //console.log('Request aborted');
        } else {
            //alert("Error");
            //message.error("Error connecting to server");
        }
        localStorage.clear();
        setIsLoggedIn(false);
        return navigate('/login');
    });
  };

  const checkUserToken = () => {
    const loggedIn = localStorage.getItem('loggedIn');
    if (!loggedIn || loggedIn === 'undefined') {
      performLogOut();
      
    }
    //navigate('/');

    setIsLoggedIn(true);
  };

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  return <React.Fragment>{isLoggedIn ? children : null}</React.Fragment>;
};

export default ProtectedRoute;
