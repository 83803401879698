import React from 'react';
import SliderLogo from '../../assets/img/ai-voices.png';
import Img1 from '../../assets/img/unmixr-lp-1.png';
import Img2 from '../../assets/img/bg-lg.png';
import Img3 from '../../assets/img/unmixr-lp-3.png';


const LPPromoItem1 = () => {
  return (
    <div className="slider-image">
        <div className="slider-content" style={{marginTop: '60px'}}>
        {/* <h4 style={{ color: '#6A3FFF' }}>Boost your productivity with Unmixr</h4> */}
          <h3 style={{fontSize: '2.5rem', lineHeight: '1', fontWeight: '900'}}>AI Text to Speech <strong style={{ color: '#6A3FFF', fontSize: '2.5rem', fontWeight: '900' }}>AI Dubbing</strong></h3>
          <h3 style={{fontSize: '2.5rem', lineHeight: '1', fontWeight: '900'}}>
             AI Chat & Copywriting
          </h3>
          <p style={{
            fontSize: '1.1rem',
            letterSpacing: '0.03rem',
            maxWidth: '680px',
            textAlign: 'center',
            margin: '30px auto 40px'
          }}>
            Simple, easy and powerful tools for your voiceover, dubbing and copywriting needs.
          </p>

          <img style={{transform: 'scale(1.4)', paddingTop: '60px'}} className='banner' src={Img2} />
        </div>
      </div>
  );
};

export default LPPromoItem1;
