import React from 'react';
import "./ContentLoader.css"

function ContentLoader({n=6}) {
  let items = [1, 2, 3, 4, 5, 6];
  if(n === 3) {
    items = [1, 2, 3];
  }

  return (
    <div style={{ overflow: 'hidden', padding: '10px', width: '100%' }}>
      {items.map((item, i) => (
        <div key={i} className="card" target="_blank">
          <div className="card__header" item={item} index={i}>
            <div>
              <img className="card__header header__img skeleton" id="logo-img" alt="" />
            </div>
            <h3 className="card__header header__title" id="card-title">
              <div className="skeleton skeleton-text"></div>
              <div className="skeleton skeleton-text"></div>
            </h3>
          </div>

          <div className="card__body">
            <div className="card__body body__text" id="card-details">
              <div className="skeleton skeleton-text skeleton-text__body"></div>
            </div>

            <div className="card__body body__img">
              <img className="skeleton" alt="" id="cover-img" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ContentLoader;
