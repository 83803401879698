import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const Auth = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUserToken = () => {

    const params = new URLSearchParams(location.search);
    const nextUrl = params.get('next');

    const loggedIn = localStorage.getItem('loggedIn');
    //console.log(userToken);
    if (!loggedIn || loggedIn === 'undefined') {
      setIsLoggedIn(false);
      if(nextUrl) {
        return navigate('/login?next=' + nextUrl);
      }
      return navigate('/login');
    }

    if(nextUrl) {
      navigate(nextUrl);
    }
    else {
      navigate('/');
    }
    setIsLoggedIn(true);
  };

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);
  return (
    <React.Fragment>
      <Outlet />
    </React.Fragment>
  );
};
export default Auth;
